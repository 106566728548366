@import '@/styles/breakpoints.module';

.card {
  --mobile-card-max-width: 343px;
  max-width: var(--mobile-card-max-width);
  padding: 16px 8px 8px 8px;
  margin-inline: auto;
  background-color: var(--color-card-bg);
  border-radius: var(--palette-border-radius-xm);

  @include tablet {
    max-width: 100%;
    padding: 32px 12px 12px 12px;
  }

  @include desktop {
    padding: 12px 12px 12px 32px;
  }
}

.splitScreen {
  @include desktop {
    display: flex;
    width: 100%;
    justify-content: space-between;

    & > * {
      width: 50%;
    }
  }
}

.content {
  text-align: center;

  .subhead {
    color: var(--color-text-primary);
    font-weight: var(--palette-font-weight-semibold);
  }

  .title {
    margin-top: 4px;
    @include tablet {
      margin-top: 8px;
    }
    @include desktop {
      margin-top: 12px;
    }
  }

  .br {
    display: block;
    @include tablet {
      display: none;
    }
    @include desktop {
      display: block;
    }
  }

  @include desktop {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 474px;
  }
}

.awardImage {
  display: flex;
  height: 155px;
  padding: 7px 0 7px;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  border-radius: var(--palette-border-radius-s);
  background-color: var(--palette-gray-900);

  img {
    height: 100%;
    width: auto;
  }

  @include tablet {
    height: 310px;
    padding: 14px 0 14px;
    margin-top: 24px;
  }

  @include desktop {
    margin-top: 0;
    margin-inline-start: 32px;
    height: 416px;
  }
}
