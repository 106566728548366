@import '@/styles/breakpoints.module';

.cardsGrid {
  width: 100%;
  display: grid;
  gap: var(--cards-grid-gap);
  justify-items: center;

  & > * {
    width: 100%;
    max-width: 335px;
  }

  @include tablet {
    grid-template-columns: 1fr 1fr;
    & > * {
      max-width: unset;
    }
  }

  @include desktop {
    justify-content: space-between;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: var(--palette-border-radius-m);
  padding: 16px;
  background-color: var(--palette-gray-900);
  box-shadow: 2px 4px 30px 0 var(--color-shadow-base);

  @include tablet {
    padding: 24px;
  }

  @include desktop {
    padding: 32px 16px;
  }
}

.wrapper {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  gap: 12px;
  @include tablet {
    gap: 16px;
  }

  @include desktop {
    max-width: 296px;
  }
}

.subtitle.subtitle {
  color: var(--color-text-primary);
}

.title {
  margin-top: 4px;
  @include tablet {
    margin-top: 8px;
  }
}

.image {
  display: flex;
  flex-direction: column;

  gap: 12px;
  @include tablet {
    gap: 16px;
  }

  img {
    display: block;
    height: 148px;
    width: auto;
    margin-inline: auto;

    @include desktop {
      height: 168px;
    }
  }

}
